.headerPad1 {
    background-color: #6edff6;
    margin-left: 1rem;
    margin-right: 1rem;
    border-radius: 0.3rem;
    color: black;
    /* overflow: hidden; */
    height: 2rem;
    /* position: relative; */
    /* width: 26.2rem; */
    padding-left: 0.6rem;
    padding-right: 0.6rem;
    word-break: break-all;
    word-wrap: break-word;
}
.headerAnimation {
    animation: shake 0.3s, colorChange 0.3s;
    animation-iteration-count: 6;
}

.scroll-text {
    /* position: absolute; */
    /* width: 100%; */
    height: 2rem;
    align-items: center;
    display: flex;
    line-height: 2rem;
    /* animation: scroll 3s linear infinite; */
}

@keyframes shake {
    0% { transform: translateX(0); }
    25% { transform: translateX(-10px); }
    50% { transform: translateX(10px); }
    75% { transform: translateX(-10px); }
    100% { transform: translateX(0); }
}


@keyframes colorChange {
    0% { background-color: #fe6ffe; }
    50% { background-color: #ffc0cb; }
    100% { background-color: #0dcaf0; }
}


@keyframes scroll {
    0% {
        top: 100%;
    }
    100% {
        top: -100%;
    }
}

.headerPad2 {
    background-color: rgb(147 197 253);
    margin-right: 1rem;
    padding-left: 0.6rem;
    padding-right: 0.6rem;
    border-radius: 0.3rem;
    color: black;
    line-height: 2rem;
}


.headerLeft {
    display: flex;
    justify-content: start;
    align-content: center;
}

@media (max-width: 768px) {
    .mobile-hide {
      display: none;
    }
    .showInMobilePhone {
        display: block;
    }
}

@media (min-width: 769px) {
    .mobile-hide {
      display: block;
    }
    .showInMobilePhone {
        display: none;
    }
}




.z1 {
    font-size: 2rem;  line-height: 2.75rem;
}

.z2 {
    font-size: 1.25rem;  line-height: 1.75rem;
}

.z3 {
    font-size: .875rem;  line-height: 1.25rem;   
}

.z4 {
    font-size: .75rem;    line-height: 1rem;
}