@media (max-width: 768px) {
    .only-pc {
        display: none;
    }

    .only-mobile {
        display: block;
    }
}

@media (min-width: 769px) {
    .only-pc {
        display: block;
    }

    .only-mobile {
        display: none;
    }
}

.portalMain {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin: 2rem;
    align-items: center;
    min-height: 50vh;
}

.portalMain img {
    margin: 0 auto;
    display: block;
    max-width: 100%;
}

.portalMainIcons {
    margin-top: 4rem;
    gap: 0.75rem;
    display: flex;
    cursor: pointer;
}
.portalMainIcons>div {
    display: flex;
    align-items: center;
    gap: 0.5rem;
}

.portalMainIcons img {
    width: 1rem;
    height: 1rem;
}

.divide {
    border: 1px solid #2f2f2f;
    margin: 1rem;
    /* border shadow */
}

.portalSegmentTitle {
    color: #777;
    font-size: 1.25rem;  line-height: 1.75rem;
}

.portalSegment {
    margin-left: 2rem;
    margin-right: 2rem;
}

.portalSegmentProjects {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-top: 1rem;
}

.portalSegmentItemTitle {
    color: #1BB2DA;
    margin-bottom: 1rem;
}

.protalSegmentPartners {
    display: flex;
    flex-wrap: wrap;
    justify-content: start;
    gap: 2rem;
    margin-top: 1rem;
    align-content: center;
    align-items: center;
}

.protalSegmentPartners img {
    width: 1rem;
    height: 1rem;
    border-radius: 50%;
}

.portalSegmentPartner {
    display: flex;
    flex-wrap: wrap;
    justify-content: start;
    gap: 0.4rem;
    align-content: center;
    align-items: center;
    cursor: pointer;
}

.roadmapArrow {
    width: 1.4rem;
}

.portalSegmentRoadmap {
    gap: 1rem;
    justify-content: space-between;
}

.portalRoadmapItemInfo>div:first-child {
    color: #1BB2DA;
}
.portalRoadmapItemInfo>div:last-child {
    color: #999;
}

.portalRoadmapItemImage {
    width: 1.6rem;
    height: 1.6rem;
    border-radius: 50%;
}

.portalRoadmapItemImage img {
    width: 100%;
    height: 100%;
}

.portalRoadmapItem {
    display: flex;
    flex-wrap: wrap;
    justify-content: start;
    gap: 0.6rem;
    align-content: center;
    align-items: center;
    cursor: pointer;
}

.portalSegmentItemImage {
    width: 4rem;
    height: 4rem;
}

.portalSegmentItemImage img {
    width: 4rem;
    height: 4rem;
}

.portalSegmentFeature {
    display: flex;
    justify-content: start;
    gap: 0.6rem;
    align-content: start;
    align-items: start;
}


/* timeline */

.timeline {
    position: relative;
    /* max-width: 800px; */
    margin: 0 auto;
    /* padding: 20px 0; */
}

.timeline::before {
    content: '';
    position: absolute;
    top: 0;
    left: 8px; /* Change this to move the line to the left */
    width: 2px;
    height: 100%;
    background-color: #fe6ffe;
}

.timeline-item {
    position: relative;
    margin: 20px 0;
}

.timeline-item::before {
    content: '';
    position: absolute;
    top: 20px;
    left: 0; /* Change this to align the circle with the line */
    width: 20px;
    height: 20px;
    background-color: #fff;
    border: 4px solid #fe6ffe;
    border-radius: 50%;
    z-index: 1;
}

.timeline-content {
    position: relative;
    width: calc(100% - 60px); /* Adjust width considering padding and circle */
    padding: 20px;
    background-color: #161719;
    border-radius: 6px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    margin-left: 40px; /* Align content to the right of the line and circle */
}

.timeline-content div {
    margin: 0 0 10px;
    font-size: 1.25rem;
    color: #1BB2DA;
}

.timeline-content p {
    margin: 0;
    color: #666;
}

.timeline-content .date {
    display: block;
    margin-top: 10px;
    font-size: 14px;
    color: #999;
}

.z1 {
    font-size: 2rem;  line-height: 2.75rem;
}

.z2 {
    font-size: 1.25rem;  line-height: 1.75rem;
}

.z3 {
    font-size: .875rem;  line-height: 1.25rem;   
}

.z4 {
    font-size: .75rem;    line-height: 1rem;
}

.bold {
    font-weight: bold;
}

.pointer {
    cursor: pointer;
}
.flex {
    display: flex;
}

.normal-color {
    color: #dee2e6 !important;
}

.portalRuleStepTitle {
    text-align: center;
    margin-bottom: 0.8rem;
    padding-bottom: 1rem;
    border-bottom: 2px solid #2f2f2f;
}

.flex {
    display: flex;
}

.justify-between {
    justify-content: space-between;
}

.align-center {
    align-items: center;
}

.link {
    color: #1BB2DA;
    cursor: pointer;
}