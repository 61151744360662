.mineTabs {
    display: flex;
    justify-content: start;
    gap: 2rem;
    padding: 0.5rem 1.6rem;
}

.mineTabActive {
    color: #fe6ffe;
    font-weight: bold;
    border-bottom: #fe6ffe 2px solid;
    padding-bottom: 0.5rem;
}

.mineTab {
    cursor: pointer;
}

.myProjectList {
    margin: 1rem;
}

.middleCenter {
    display: flex;
    justify-content: center;
    align-items: center;

}

@media (max-width: 768px) {
    .only-pc {
        display: none;
    }

    .only-mobile {
        display: block;
    }

    .middleCenter>div {
        margin: auto;
        width: 100%;
        margin-left: 2rem;
        margin-right: 2rem;
    }
}

@media (min-width: 769px) {
    .only-pc {
        display: block;
    }

    .only-mobile {
        display: none;
    }

    .middleCenter>div {
        margin: auto;
        width: 40%;
    }
}

.margin-bottom {
    margin-bottom: 1rem;
}

.padding-left {
    padding-left: 1rem;
}

.padding-right {
    padding-right: 1rem;
}