@media (max-width: 768px) {
    .only-pc {
        display: none;
    }

    .only-mobile {
        display: block;
    }
}

@media (min-width: 769px) {
    .only-pc {
        display: block;
    }

    .only-mobile {
        display: none;
    }
}

.headerPad1 {
    background-color: #6edff6;
    margin-left: 1rem;
    margin-right: 1rem;
    border-radius: 0.3rem;
    color: black;
    overflow: hidden;
    height: 2rem;
    padding-left: 0.6rem;
    padding-right: 0.6rem;
    /* word-break: break-all;
    word-wrap: break-word; */
    /* eclipse */
    white-space: nowrap;
    text-overflow: ellipsis;
}
.headerAnimation {
    animation: shake 0.3s, colorChange 0.3s;
    animation-iteration-count: 6;
}

.scroll-text {
    /* position: absolute; */
    /* width: 100%; */
    height: 2rem;
    align-items: center;
    display: flex;
    line-height: 2rem;
    /* animation: scroll 3s linear infinite; */
}

@keyframes shake {
    0% { transform: translateX(0); }
    25% { transform: translateX(-10px); }
    50% { transform: translateX(10px); }
    75% { transform: translateX(-10px); }
    100% { transform: translateX(0); }
}


@keyframes colorChange {
    0% { background-color: #fe6ffe; }
    50% { background-color: #ffc0cb; }
    100% { background-color: #0dcaf0; }
}


@keyframes scroll {
    0% {
        top: 100%;
    }
    100% {
        top: -100%;
    }
}

.headerPad2 {
    background-color: rgb(147 197 253);
    margin-right: 1rem;
    margin-left: 1rem;
    padding-left: 0.6rem;
    padding-right: 0.6rem;
    border-radius: 0.3rem;
    color: black;
    white-space: nowrap;
    text-overflow: ellipsis;
    line-height: 2rem;
    height: 2rem;
    line-height: 2rem !important;
}

.detail2Body {
    margin-top: 1rem;
}

.btnMargin {
    width: 80%;
    margin: auto auto;
}

.detail2ProjecImage img {
    width: 10rem;
    height: 10rem;
}
.detail2ProjecImage > div {
    color: #fe6ffe;
    text-align: center;
    margin-top: 0.5rem;
}
.detail2Project {
    display: flex;
    justify-content: start;
    align-items: start;
    margin-top: 1rem;
    padding: 0.8rem;
    /* background-color: rgb(46, 48, 58); */
    border-radius: 0.3rem;
    font-size:1rem;
    line-height: 1.5;
    /* color: rgb(148 163 184); */
}

.detail2ProjectInfo {
    margin-left: 1rem;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    width: 70%;
    text-align: left;
}

.detail2ProjectSocial {
    display: flex;
    flex-wrap: wrap;
    justify-content: start;
    gap: 1rem;
    align-content: center;
    align-items: center;
}

.detail2ProjectSocial > div {
    color: #1BB2DA;
    cursor: pointer;
}

.detail2hightlight {
    background-color: rgb(46, 48, 58);
    padding: 1rem;
    margin: 1rem;
    border-radius: 10px;
    font-size: 1rem;
    word-break: break-all;
}

.detail2info1 {
    margin-left: 1rem;
    margin-right: 1rem;
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
    align-items: center;
}

.detail2info2 {
    margin-left: 1rem;
    margin-right: 1rem;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
}

a{
    color:#1BB2DA
}

.detail2ProjectInfo > div:first-child {
    color: #1BB2DA;
}

.detail2info1 > div:nth-child(3) {
    color: #1BB2DA;
    cursor: pointer;
}

.detail2info2item {
    display: flex;
    gap: 0.5rem;
    align-items: center;
    font-size: .875rem;  line-height: 1.25rem;   
}
.detail2info2item > div:first-child {
    font-weight: bold;
}
.detail2info2item > div:nth-child(2) {
    font-weight: normal;
    display: flex;
    align-items: center;
}


.z1 {
    font-size: 2rem;  line-height: 2.75rem;
}

.z2 {
    font-size: 1.25rem;  line-height: 1.75rem;
}

.z3 {
    font-size: .875rem;  line-height: 1.25rem;   
}

.z4 {
    font-size: .75rem;    line-height: 1rem;
}

.bold {
    font-weight: bold;
}

.detail2marketInfo {
    margin-top: 1rem;
    padding: 0.8rem;
    background-color: rgb(46, 48, 58);
    border-radius: 0.3rem;
    font-size: .875rem;  line-height: 1.25rem;   
    /* color: rgb(148 163 184); */
    color: #dee2e6;
    display: flex;
    justify-content: space-between;
    margin: 0 1rem;
}

.detail2marketInfo>div {
    display: flex;
    gap: 1.4rem;
}

.detail2marketInfo>div>div {
    display: flex;
    gap: 0.5rem;
}

.detail2marketInfo img {
    width: 1rem;
    height: 1rem;
    border-radius: 5px;
}

.pointer {
    cursor: pointer;
}


.btn-warning {
    --bs-btn-bg: #fe6ffe;
    --bs-btn-hover-border-color: #fe6ffe;
    --bs-btn-border-color: #fe6ffe;
    --bs-btn-active-bg: #fe6ffe;
    --bs-btn-active-border-color: #fe6ffe;
    --bs-btn-disabled-bg: #fe6ffe;
    --bs-btn-disabled-border-color: #fe6ffe;
    --bs-btn-hover-bg: #fe6ffe;
}

.hide {
    display: none;
}