.infoLine {
    display: flex;
    justify-content: start;
    align-items: center;
    line-height: 2rem;
    gap: 1rem;
}

.stepItems {
    margin-bottom: 1rem;
    padding: 1rem;
    border: 1px solid #495057;
    border-radius: 0.2rem;
}

.stepItems.active {
    color: #fe6ffe;
    border: 1px solid #fe6ffe;
}

.infoLine>div:first-child {
    font-weight: bold;
}

.btn-warning {
    --bs-btn-bg: #fe6ffe;
    --bs-btn-hover-border-color: #fe6ffe;
    --bs-btn-border-color: #fe6ffe;
    --bs-btn-active-bg: #fe6ffe;
    --bs-btn-active-border-color: #fe6ffe;
    --bs-btn-disabled-bg: #fe6ffe;
    --bs-btn-disabled-border-color: #fe6ffe;
    --bs-btn-hover-bg: #fe6ffe;
}